<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="168" :lazy-src="$root.imagePlaceholder" src="../assets/2024/EMEA_ShuttleBanner_1125x450.png"></v-img>

                <v-card flat>
                    <v-card-title class="pb-2">Evening Shuttles to Trastevere & Navona for 13, 14 and 16 October</v-card-title>
                    <v-card-text class="pb-0">
                        <p>Transfers to each location take approximately 30-45 minutes.</p>
                        <p><i>Shuttle seat availability is on a first come, first served basis.</i></p>
                    </v-card-text>
                    <div>
                        <v-card-title class="pb-2 pt-2">Shuttle Drop Off & Pick up / Trastevere</v-card-title>
                        
                        <v-card-text class="pb-2">
                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Shuttle Drop Off & Pick up / Trastevere', 41.89255082494687, 12.469447728835478)">Get Directions</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Shuttle Drop Off & Pick up / Trastevere', 41.89255082494687, 12.469447728835478)">Get Directions</a>
                                </div>
                            </div>
                            <div style="max-width: 65%; margin: 0 auto;">
                                <v-card-title style="justify-content: center;" class="pb-2 pl-0 pr-0 pt-2 text-center">Trastevere Shuttles</v-card-title>
                                <table class="custom_table">
                                    <tr>
                                        <th style="width: 140px;">Depart<br>Rome Cavalieri</th>
                                        <th style="width: 140px;">Depart<br>Trastevere</th>
                                    </tr>
                                    <tr>
                                        <td>18:30</td>
                                        <td>19:15</td>
                                    </tr>
                                    <tr>
                                        <td>19:00</td>
                                        <td>19:45</td>
                                    </tr>
                                    <tr>
                                        <td>19:30</td>
                                        <td>20:15</td>
                                    </tr>
                                    <tr>
                                        <td>20:00</td>
                                        <td>20:45</td>
                                    </tr>
                                    <tr>
                                        <td>20:30</td>
                                        <td>21:15</td>
                                    </tr>
                                    <tr>
                                        <td>21:00</td>
                                        <td>21:45</td>
                                    </tr>
                                    <tr>
                                        <td>21:30</td>
                                        <td>22:15</td>
                                    </tr>
                                    <tr>
                                        <td>22:15</td>
                                        <td>23:00</td>
                                    </tr>
                                </table>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Shuttle Drop Off & Pick up / Navona</v-card-title>
                        
                        <v-card-text class="pb-2">
                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Shuttle Drop Off & Pick up / Navona', 41.90242382359133, 12.47237527116452)">Get Directions</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Shuttle Drop Off & Pick up / Navona', 41.90242382359133, 12.47237527116452)">Get Directions</a>
                                </div>
                            </div>
                            <div style="max-width: 65%; margin: 0 auto;">
                                <v-card-title style="justify-content: center;" class="text-center pb-2 pl-0 pr-0 pt-2">Navona Shuttles</v-card-title>
                                <table class="custom_table">
                                    <tr>
                                        <th style="width: 140px;">Depart<br>Rome Cavalieri</th>
                                        <th style="width: 140px;">Depart<br>Navona</th>
                                    </tr>
                                    <tr>
                                        <td>18:30</td>
                                        <td>19:15</td>
                                    </tr>
                                    <tr>
                                        <td>19:00</td>
                                        <td>19:45</td>
                                    </tr>
                                    <tr>
                                        <td>19:30</td>
                                        <td>20:15</td>
                                    </tr>
                                    <tr>
                                        <td>20:00</td>
                                        <td>20:45</td>
                                    </tr>
                                    <tr>
                                        <td>20:30</td>
                                        <td>21:15</td>
                                    </tr>
                                    <tr>
                                        <td>21:00</td>
                                        <td>21:45</td>
                                    </tr>
                                    <tr>
                                        <td>21:30</td>
                                        <td>22:15</td>
                                    </tr>
                                    <tr>
                                        <td>22:15</td>
                                        <td>23:00</td>
                                    </tr>
                                </table>
                            </div>
                        </v-card-text>
                    </div>
                    <v-card-title class="pb-2">Shuttles to City for 14, 15 and 16 October</v-card-title>
                    <v-card-text class="pb-0">
                        <p>Transfers take approximately 30 minutes.</p>
                        <p><i>Shuttle seat availability is on a first come, first served basis.</i></p>
                    </v-card-text>
                    <div>
                        <v-card-title class="pb-2 pt-2">Shuttle Drop Off & Pick up / City</v-card-title>
                        <v-card-text class="pb-2">
                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Shuttle Drop Off & Pick up / City', 41.90559180374651, 12.492164542329038)">Get Directions</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Shuttle Drop Off & Pick up / City', 41.90559180374651, 12.492164542329038)">Get Directions</a>
                                </div>
                            </div>
                            <div style="max-width: 65%; margin: 0 auto;">
                                <v-card-title style="justify-content: center;" class="pb-2 pl-0 pr-0 pt-2 text-center">City Shuttles</v-card-title>
                                <table class="custom_table">
                                    <tr>
                                        <th style="width: 140px;">Depart<br>Rome Cavalieri</th>
                                        <th style="width: 140px;">Depart<br>City</th>
                                    </tr>
                                    <tr>
                                        <td>12:30</td>
                                        <td>13:00</td>
                                    </tr>
                                    <tr>
                                        <td>13:30</td>
                                        <td>14:00</td>
                                    </tr>
                                    <tr>
                                        <td>14:30</td>
                                        <td>15:00</td>
                                    </tr>
                                    <tr>
                                        <td>15:30</td>
                                        <td>16:00</td>
                                    </tr>
                                </table>
                            </div>
                        </v-card-text>
                    </div>
                </v-card>
                
                <overview :content="overviewContent" v-if="overviewContent"></overview>

                <accordians :content="accordianContent" v-if="accordianContent"></accordians>

                <div center-align light-opacity-color class="pa-8" v-if="!overviewContent && (!listContents || !listContents?.length) && !loading">
                    No Information
                </div>
            </template>
            
        </div>

        <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('eveningevents')">Refresh Now</v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import overview from './sub_components/overview'
import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        'accordians':accordians
    },
    methods:{
        fn_updateAvailable: function(dataObj){
            let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
            if(updateObj.pageName==='shuttle'){
                if(this.overviewContent || this.accordianContent){
                    this.snackbarMsg = true;
                }
                else{
                    this.fn_getData('shuttle');
                }
            }
        },

        fn_openLocationOnMap: function(placeName, latitude, longitude){
            let dataObj = {};
            dataObj.PlaceName = placeName;
            dataObj.Latitude = latitude;
            dataObj.Longitude = longitude;
            this.fn_captureMetrics(placeName);
           
            this.postData('OpenMaps', dataObj, 
                {
                    onSuccess: function(response){
                        console.log("Success Map");
                        console.log(response);
                    },
                    onError: function(error){
                        console.log("Error Map");
                        console.log(error);
                    }
                }
            );
        },

        fn_captureMetrics: function(objecttitle){
            var tempObj = { ObjectTitle: objecttitle, ObjectType: this.$route.name, ActionType: 'Link', LinkPath: null };
            this.$root.fn_postAccordianClickLinkMetrics(tempObj);
        }
    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Shuttle Schedule', null);
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        // this.fn_getData('shuttle');
        this.fn_getPageDataFromServer('shuttle');
    }
}
</script>