import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import './actioninterface.js';
// import './firebase.js'

import axios from 'axios';
import { CometChat } from "@cometchat-pro/chat";

Vue.use(require('vue-moment'));

// var authRef = require('./firebase.js').fireConfig.authRef

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app"; 

// import { getAnalytics } from "firebase/analytics";

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';
// import "firebase/compat/auth";
// import "firebase/compat/storage"

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }
// const auth = firebase.auth();

// import { getAuth, onAuthStateChanged, signInAnonymously, 
//   // GoogleAuthProvider, // signInWithPopup, // signOut, // authStateObserver
// } from 'firebase/auth';

  // import { getFirestore, collection, addDoc, query, orderBy, limit, onSnapshot, serverTimestamp,
  //   // setDoc,
  //   // updateDoc,
  //   // doc,
  //   // startAfter,
  //   // startAfter
  //   // endAt
  // } from 'firebase/firestore';

  // import {
  //   getStorage,
  //   ref,
  //   uploadBytesResumable,
  //   getDownloadURL,
  // } from 'firebase/storage';
  // import { getMessaging, getToken, onMessage } from 'firebase/messaging';
  // import { getPerformance } from 'firebase/performance';
  
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDGdVsRNN5w8eJ19N_V1VbYbe5WRUNu1BU",
//   authDomain: "tripchat-40f38.firebaseapp.com",
//   projectId: "tripchat-40f38",
//   storageBucket: "tripchat-40f38.appspot.com",
//   messagingSenderId: "847554334910",
//   appId: "1:847554334910:web:e571070bb4980782d14eef"
// };

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

// Create a root reference
// const firebaseStorage = firebase.storage();

// const firestore = firebase.firestore();

// Initialize Firebase
// initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// var userId;
// Signs-in Friendly Chat.
// function signIn() {
    // Sign in Firebase using popup auth and Google as the identity provider.
    // var provider = new GoogleAuthProvider();
    // await signInWithPopup(getAuth(), provider);

    // const auth = getAuth();
    // signInAnonymously(auth).then(() => {
    //   // Signed in..
    //   alert('signed in');
    //   loadMessages();
    // })
    // .catch((error) => {
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   console.log(errorCode);
    //   console.log(errorMessage)
    //   // ...
    // });

    // firebase.auth().signInAnonymously().then(() => {
    //   // loadMessages();
    //   watchMessages();
    // })
    // .catch((error) => {
    //   var errorCode = error.code;
    //   var errorMessage = error.message;
    //   console.log(errorCode);
    //   console.log(errorMessage);
    // });

    // onAuthStateChanged(getAuth(), (user) => {  
    // firebase.auth().onAuthStateChanged((user)=>{
    //   if (user) {
    //     const uid = user.uid;
    //     console.log(uid);
    //     userId = user.uid;
    //   } else {
    //     console.log("not logged in");
    //   }
    // })
      // if (user) {
      //   const uid = user.uid;
      //   console.log(uid);
      //   // getUserName();
      //   console.log(getAuth().currentUser);
      //   userId = user.uid;
      //   // ...
      // } else {
      //   // User is signed out
      //   // ...
      // }
    // });
// }

// var myName;
// let latestMessageWatcher = null;

// function watchMessages(){
//   let query = firebase.firestore().collection("pinnacle").doc("chat").collection("messages").orderBy('timestamp', 'desc').limit(1);
//   latestMessageWatcher = query.onSnapshot((snapshot) => {
//     snapshot.docChanges().forEach(function(change) {
//       if (change.type === 'removed') {
//         // deleteMessage(change.doc.id);
//       } 
//       else if(change.type === 'modified'){
//         // messageupdated
//       }
//       else {
//         let messageObj = change.doc.data();
//         messages.push(messageObj.text);
//         console.log(messages);
//       }
//     });
//   });
// }

// Saves a new message to Cloud Firestore.
// async function saveMessage(msgType, value) {
//   let msgObj = {
//     "sender": myName, 
//     "uid":userId, 
//     type:msgType, 
//     "path":msgType == 'image' ? value : null, 
//     "link":msgType == 'link' ? value : null,
//     "text": msgType == 'text' ? value : null,
//     "timestamp": firebase.firestore.FieldValue.serverTimestamp()
//   }
//   // Add a new message entry to the Firebase database.
//   try {
//     let doc = firebase.firestore().collection("pinnacle").doc("chat").collection("messages");
//     await doc.add(msgObj);
//   }
//   catch(error) {
//     console.error('Error writing new message to Firebase Database', error);
//   }
// }

// async function saveMessage() {
//   // alert(getUser());
//   // alert(messageText);
//   // Add a new message entry to the Firebase database.
//   let messageText = document.getElementById("message").value;
//   try {
//     let doc = firebase.firestore().collection("messages").doc('testdoc');
//     await doc.set({
//         sender: myName,
//         uid:userId,
//         text: messageText,
//         // profilePicUrl: getProfilePicUrl(),
//         timestamp: firebase.firestore.FieldValue.serverTimestamp()
//     });
//     // await addDoc(collection(getFirestore(), 'messages'), {
//     //   sender: myName,
//     //   uid:userId,
//     //   text: messageText,
//     //   // profilePicUrl: getProfilePicUrl(),
//     //   timestamp: serverTimestamp()
//     // });
//   }
//   catch(error) {
//     console.error('Error writing new message to Firebase Database', error);
//   }
// }

// function uploadImage(file){
//   let storageRef = firebaseStorage.ref();
//   let path = 'pinnacle/messages/'+userId+'/'+file.fileName
// }
// let fileDataString = '';
// let bytesArray = [];
// function fn_setFileData(fileStringChunk, isComplete){
//   console.log(fileStringChunk);
//   console.log(isComplete);
//   let chunk = new TextEncoder("utf-8").encode(fileStringChunk);
//   console.log(chunk);
//   if(!JSON.parse(isComplete)){
//     for (let i = 0; i < chunk.byteLength; i++) {
//       bytesArray.push(chunk[i]);
//     }
//   }
//   else{
//     console.log(bytesArray);
//     // Vue.prototype.uploadfile(bytesArray);
//   }
//   // if(!JSON.parse(isComplete)){
//   //     fileDataString += fileStringChunk;
//   // }
//   // else{
//   //     console.log(fileDataString);
//   //     let utf8Encode = new TextEncoder();
//   //     utf8Encode.encode(fileDataString);
//   //     console.log(utf8Encode);
//   //     // Vue.prototype.uploadfile(fileDataString);
//   // }
// }

// var messages = [];
// let lastVisible = null;

// Loads chat messages history and listens for upcoming ones.
// function loadMessages() {
//   // Create the query to load the last 12 messages and listen for new ones.
//   let query = null;

//   if(!messages.length || !lastVisible){
//     query = firebase.firestore().collection("pinnacle").doc("chat").collection("messages").orderBy('timestamp', 'desc').limit(3);
//   }
//   else{
//     query = firebase.firestore().collection("pinnacle").doc("chat").collection("messages").orderBy('timestamp', 'desc').startAfter(lastVisible).limit(3);
//   }
  
//   // Start listening to the query.
//   let queryWatcher = query.onSnapshot((snapshot) => {
//     lastVisible = snapshot.docs[snapshot.docs.length-1];
//     for(let i=0; i<snapshot.docs.length; i++){
//       let messageObj = snapshot.docs[i].data();
//       messages.push(messageObj.text);
//     }
//     queryWatcher();
//   });
// }
// function loadMessages() {
//   // alert('in');
//   // Create the query to load the last 12 messages and listen for new ones.
//   // const recentMessagesQuery = query(collection(getFirestore(), 'messages'), orderBy('timestamp', 'desc'), limit(3));

//   const recentMessagesQuery = firebase.firestore().collection("messages").orderBy('timestamp', 'desc').limit(3);
  
//   // Start listening to the query.
//   // onSnapshot(recentMessagesQuery, function(snapshot) {
//   recentMessagesQuery.onSnapshot((snapshot) => {
//     // startIndex += snapshot.docs.length;
//     snapshot.docChanges().forEach(function(change) {
//       if (change.type === 'removed') {
//         // deleteMessage(change.doc.id);
//       } 
//       else if(change.type === 'modified'){
//         // messageupdated
//       }
//       else {
//         var message = change.doc.data();
//         // console.log(message)
//         // startIndex++;
//         console.log(message.text);
//         // displayMessage(change.doc.id, message.timestamp, message.name,
//         //               message.text, message.profilePicUrl, message.imageUrl);
//       }
//     });
//   });
// }

// function uploadfile(file){
//   let storageRef = firebase.storage().ref();
//   let storageObj = firebase.storage;

//   let path = '';

//   let type = file.type.indexOf('image') == 0 ? 'image' : 'document';

//   if(type == 'image'){
//     path = "pinnacle/chat/images/"+userId+"/"+file.name;
//   }
//   else{
//     path = "pinnacle/chat/documents/"+userId+"/"+file.name;
//   }

//   let uploadTask = storageRef.child(path).put(file);

//   // Listen for state changes, errors, and completion of the upload.
//   uploadTask.on(storageObj.TaskEvent.STATE_CHANGED, // or 'state_changed'
//     function(snapshot) {
//       // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//       var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//       console.log('Upload is ' + progress + '% done');
//       // setting up buffer size
//       // self.fn_setStatus(progress)
//       switch (snapshot.state) {
//         case storageObj.TaskState.PAUSED: // or 'paused'
//           console.log('Upload is paused');
//           break;
//         case storageObj.TaskState.RUNNING: // or 'running'
//           console.log('Upload is running');
//           break;
//       }
//     }, function(error) {

//     // A full list of error codes is available at
//     // https://firebase.google.com/docs/storage/web/handle-errors
//     switch (error.code) {
//       case 'storage/unauthorized':
//         console.log('storage/unauthorized');
//         // User doesn't have permission to access the object
//         break;

//       case 'storage/canceled':
//         // callback('cancelled');
//         console.log('cancelled');
//         // User canceled the upload
//         break;

//       case 'storage/unknown':
//         console.log('storage/unknown');
//         // Unknown error occurred, inspect error.serverResponse
//         break;
//     }
//   }, function() {
//     // Upload completed successfully, now we can get the download URL
//     uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
//       console.log('File available at', downloadURL);
//       saveMessage(type, downloadURL);
//       // callback(downloadURL);
//     });
//   });
// }

// Signs-out of Friendly Chat.
// function signOutUser() {
//     // Sign out of Firebase.
//     signOut(getAuth());
// }

// Initialize firebase auth
// function initFirebaseAuth() {
//     // Listen to auth state changes.
//     onAuthStateChanged(getAuth(), authStateObserver);
// }

// Returns the signed-in user's profile Pic URL.
// function getProfilePicUrl() {
//     return getAuth().currentUser.photoURL || '/images/profile_placeholder.png';
// }

// Returns the signed-in user's display name.
// function getUserName() {
//     return getAuth().currentUser.displayName;
// }

// Returns true if a user is signed-in.
// function isUserSignedIn() {
//     return !!getAuth().currentUser;
// }

Vue.config.productionTip = false;

Vue.prototype.$eventHub = new Vue();

Vue.use(vuetify,{
    iconfont: 'md'
})

// window.addEventListener('load', () => {
// //     if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) !== true){
// //         // true for mobile device
// //         document.querySelector('body').style.width = '420px';
// //         document.querySelector('body').style.margin = 'auto';
// //         document.querySelector('body').style.background = '#eee';
// //         document.querySelector('body').style.zoom = '85%';
// //     }

//     // document.getElementById('signinbtn').addEventListener('click', ()=>{
//     //     signIn();
//     // })

//     document.getElementById('sendbtn').addEventListener('click', ()=>{
//         // saveMessage();
//         let messageText = document.getElementById("message").value;
//         saveMessage('text', messageText);
//         document.getElementById("message").value = '';
//     })

//     document.getElementById('loadmessagebtn').addEventListener('click', ()=>{
//         loadMessages();
//     })

//     document.getElementById('destroywatcher').addEventListener('click', ()=>{
//       latestMessageWatcher();
//     })

//     // document.getElementById('imagefile').addEventListener('change', e=>{
//     //   if (e.target.files[0]) {
//     //     console.log(e.target.files[0]);
//     //     // document.body.append('You selected ' + e.target.files[0].name);
//     //     // uploadImage(e.target.files[0]);
//     //   }
//     // })

//     // myName = prompt("Enter your name");
//     myName = "test user";
// });

// Vue.prototype.userId = null;

// Vue.prototype.fn_signinToFirebase = function(){
//   authRef.signInAnonymously().then(() => {
//       // fn_watchMessages();
//       console.log('signin');
//   })
//   .catch((error) => {
//       console.log(error.code);
//       console.log(error.message);
//   });

//   authRef.onAuthStateChanged((user)=>{
//       if (user) {
//         console.log(user.uid);
//         Vue.prototype.userId = user.uid;
//       } else {
//         console.log("not logged in");
//       }
//   })
// },

if(location.search.indexOf('cache=true') !== -1){
  console.log('cache');
    if ('caches' in window) {
      console.log('cache in window');
      caches.keys().then(function(cacheNames) {
        console.log('cache key');
        cacheNames.forEach(function(cacheName) {
          console.log('cache remove');
          caches.delete(cacheName);
        });
      });
    }
  }

var _envirenment = null;
if(localStorage.getItem('Envirenment')){
  _envirenment = localStorage.getItem('Envirenment');
}

Vue.prototype.guidWeb = function(){
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

// Check that service workers are supported
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('../../service-worker.js').then(registration => {
          console.log('sw');
        console.log('SW registered: ', registration);        
        // Listen for new service worker installations
       

      }).catch(registrationError => {
        console.log(registrationError);
      });
  
      if (navigator.serviceWorker.controller) {
        console.log('This page is currently controlled by:', navigator.serviceWorker.controller);
      }
  
      navigator.serviceWorker.oncontrollerchange = function() {
        console.log('This page is now controlled by:', navigator.serviceWorker.controller);
        console.log('Install complete. calling update');
        // fn_onUpdateFound();
        // if(window.matchMedia('(display-mode: standalone)').matches){
        //   fn_onUpdateFound();
        // }
      };
  
    });
}

console.log(Vue.prototype);
console.log('update check 1');

new Vue({
    router,
    vuetify,
    data(){
        return{
            //Envirenment :_envirenment,
            Envirenment: _envirenment,
            selectedTrip:null,
            themeColor:null,
            platform:null,
            tripSiteId:null,
            loader:false,
            alertDialog:false,
            notificationObj:null,
            tripOnLoadData:{NotificationCount:0, AttendeeInfo:null},
            imagePlaceholder:"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAZABkAAD/7AARRHVja3kAAQAEAAAAHgAA/9sAQwAKBwcJBwYKCQgJCwsKDA8ZEA8ODg8eFhcSGSQgJiUjICMiKC05MCgqNisiIzJEMjY7PUBAQCYwRktFPko5P0A9/9sAQwELCwsPDQ8dEBAdPSkjKT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09/8AAEQgAZAB4AwEiAAIRAQMRAf/EABsAAQEAAwEBAQAAAAAAAAAAAAAFAQIEBgMH/8QALRAAAgECBAMIAgMBAAAAAAAAAAIBAwQFERIhFTFBExQjUVNhktFCkSRisXH/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgH/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwD9TABqAAAAAAAAAAAAAAAAAAAAAAAAAAAAT+LU++9hp8PVlr9ygFAACQAAAAAAAAAAAAAOHFLvu1DQjeJU2j2jzO13WlTZ3bKI3khUv5961eu2VJd5z5ZdICmvDqnD+8fnz0/1KeGXfeaGh28SntPvHmdXbUmodp2i9lp59CJLdwvYq0W10W3jLrHWALwMI8PTh0bOJ3iSVc4syXsRS3pI2Te/mBWBhHh6cOjZxO8SZCQAAAAAAAAA57y5i1tmqfnyWPcCfjN3qbu6NtG7/wDfIlap05atueQlpZmlt5neZAU21t2ejU2jnl0zNdXToABVwe51K1s7bTnpn/YJ9zbtbV2pv05T5wfJWlGV12mN4ksXCxieHRXRfFp84/2ANMGu9Ld3dvdPosHklaUaJXaY3iT0tlcxdW0P+fJo9wOgABIAAAAAE7EcPr3lWJSouiF2ic+f6KICkLgtz6lP5T9Dgtz6lP5T9F0AQuC3PqU/lP0OC3PqU/lP0XQBC4Lc+pT+U/R24dY17Oq0vUWUleUZ8/0UABHrYNUeq7pUpwktMxG50Yfh9WzqtL1FlJXLKPMoAAAAkAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAf/2Q==",
            baseUrl: process.env.NODE_ENV === 'production' ? 'https://platinumclubandgoldclub.azurewebsites.net' : 'https://platinumclubandgoldclub.azurewebsites.net',
            //baseUrl: process.env.NODE_ENV === 'production' ? 'https://platinumclubandgoldclub.azurewebsites.net' : 'https://platinumclubandgoldclub.azurewebsites.net',
            //baseUrl: _envirenment == 3 ? 'https://platinumclubandgoldclub-uat.azurewebsites.net' : 'https://platinumclubandgoldclub-uat.azurewebsites.net',
            DeviceID: null,
            appsnackbar:false,
            isTestUser: false,
            permissionType: null,
            unreadMessageCount: 0,
            PdfHeader: 'Hotel / Resort Map',
            isChatViewOpen:false
        }
    },
    methods:{
      fn_testFn: function(){
        this.getData("TestFunction", null, {
          onSuccess: function(response){
            console.log(response);
          },
          onError: function(error){
            console.log(error); 
          }
        });
      },

      fn_getAllPages: function(){
        let self = this;
        this.getData("AllTripPage", null, {
          onSuccess: function(response){
            console.log(response);
            localStorage.setItem('tripallpages_'+self.tripSiteId, 'pagesloaded');
          },
          onError: function(error){
            console.log(error); 
          }
        });
      },

      fn_logOut: function(page){
        let self = this;
        this.getData("Logout", null, {
          onSuccess: function(response){
            console.log(response);
            self.fn_logoutComet();
            self.fn_clearVariables(page);
          },
          onError: function(error){
            console.log(error); 
            self.fn_showToast("exception");
          }
        });
      },

      fn_moveToLogin: function(){
        this.$router.push({name:'sendotp'});
      },

      fn_clearVariables: function(page){
        this.selectedTrip = null;
        this.themeColor = null;
        this.tripSiteId = null;
        this.tripOnLoadData = {NotificationCount:0, AttendeeInfo:null};
        localStorage.clear();
        this.fn_showToast("You have been logged out.");
        page != 'sendotp' ? this.$router.push({path:'/'}) : '';
      },

      fn_logoutComet: function(){
        CometChat.getLoggedinUser().then(
          user => {
              if(user){
                  CometChat.logout().then(
                      () => {
                          console.log("Logout completed successfully");
                      },error=>{
                          console.log("Logout failed with exception:",{error});
                      }
                  );
              }
          }, error => {
              console.log("Something went wrong", error);
          }
        )
      },

      fn_copyLogs: function(){
        this.getData("CopyLog", null, {
          onSuccess: function(response){
            console.log(response);
          },
          onError: function(error){
            console.log(error);
          }
        });
      },

      fn_token: function(){
        this.$router.push('/token')
      },

      fn_isJsonString: function(text){
          if (typeof text!=="string"){
              return false;
          }
          try{
              var json = JSON.parse(text);
              return (typeof json === 'object');
          }
          catch (error){
              return false;
          }
      },

      fn_showToast: function(msg){
        let message;
        if(msg === 'exception'){
          message = 'Something went wrong. Please try again'
        }
        else if(msg === 'nointernet'){
          message = 'Internet not available'
        }
        else{
          message = msg
        }
        this.getData('ShowToast', message, {
          onSuccess: function(response){
            console.log(response);
          },
          onError: function(error){
            console.log(error);
          }
        })
      },

      fn_getToken: function(callback){
        this.getData("FirebaseToken", null, 
            {
              onSuccess: function(response){
                  console.log(response);
                  callback ? callback(response) : '';
              },
              onError: function(error){
                  console.log(error);
                  callback('error');
              }
            }
        );
      },

      fn_getNotificationCount: function(){

        console.log('notification');
        this.tripOnLoadData.NotificationCount = 0;
        
          if(!navigator.onLine){
            this.$root.fn_showToast("nointernet");
            return;
          }

          this.$root.fn_getToken(token=>{
              if(token == 'error'){
                  this.$root.fn_showToast('exception');
              }
              else{
                  var headersObj = {
                      headers: { 
                            'Authorization': 'bearer '+token
                          }
                      };

                  axios.get(this.baseUrl + "/api/mobileapp/attendee/personalinformation", headersObj, {timeout:30000})
                  .then(response => {
                      this.loading = false;
                      console.log(response);
                      this.tripOnLoadData = response.data;
                      this.tripOnLoadData.AttendeeInfo = JSON.parse(response.data.ProfileDetails);
                      this.fn_saveLocalData('AttendeeInfo', JSON.parse(response.data.ProfileDetails));

                      // getting all pages data single time for each trip
                      !localStorage.getItem('tripallpages_'+this.tripSiteId) ? this.fn_getAllPages() : '';
                      
                  }).catch(e => {
                      this.loading = false;
                      console.log(e);
                      // this.$root.fn_showToast('exception');
                  })
              }
          })
          
      },

      fn_checkDeviceLogin: function(){

        console.log('device login');
       
        console.log(this.DeviceID);
        let self = this;
        
        if(!navigator.onLine){
          this.$root.fn_showToast("nointernet");
          return;
        }

        if(localStorage.getItem('DeviceID')){
          this.DeviceID = localStorage.getItem('DeviceID');
        }

        this.$root.fn_getToken(token=>{
            if(token == 'error'){
                this.$root.fn_showToast('exception');
            }
            else{

              var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token
                    }
                };

                let dataObj = {};
                dataObj.DeviceID = self.DeviceID;

                console.log(dataObj + 'Test');

              axios.post(self.baseUrl + "/api/mobileapp/activedecive", dataObj, headersObj, {timeout:30000})
              .then(response => {
                //self.loading = false;
                console.log(response);
                if(response.data.Status == false){
                  self.selectedTrip = null;
                  self.themeColor = null;
                  self.tripSiteId = null;
                  self.tripOnLoadData = {NotificationCount:0, AttendeeInfo:null};
                  localStorage.clear();
                  self.$router.push('/sendotp');
                }
               
                  
              }).catch(e => {
                self.loading = false;
                console.log(e);
                  // this.$root.fn_showToast('exception');
              })
            }
        })
      },

      async fn_postClickMetrics(ObjectType, ObjectTitle, ObjectID){

        console.log('Click Metrics');
        //console.log(this.tripSiteId);
        
        if(!navigator.onLine){
          this.$root.fn_showToast("nointernet");
          return;
        }

        var token = await new Promise((resolve) => {
          this.$root.fn_getToken((token) => {
              resolve(token);
          });
        });

        if(token == 'error'){
          this.$root.fn_showToast('exception');
        }
        else{
            var headersObj = {
              headers: { 
                'Authorization': 'bearer '+token
              }
            };

            let dataObj = {};
            dataObj.Action = "Click";
            dataObj.ObjectType = ObjectType;
            dataObj.ObjectTitle = ObjectTitle;
            dataObj.ObjectID = ObjectID;
            dataObj.TripSiteID = this.tripSiteId;

            axios.post(this.baseUrl + "/api/mobileapp/metrics", dataObj, headersObj, {timeout:30000})
            .then(response => {
                this.loading = false;
                console.log(response);
                
            }).catch(e => {
                this.loading = false;
                console.log(e);
                // this.$root.fn_showToast('exception');
            })
        }
      },

      async fn_postOpenMetrics(ObjectType, ObjectTitle, ObjectID){

        console.log('Open Metrics');
        
        if(!navigator.onLine){
          this.$root.fn_showToast("nointernet");
          return;
        }

        var token = await new Promise((resolve) => {
          this.$root.fn_getToken((token) => {
              resolve(token);
          });
        });

        if(token == 'error'){
          this.$root.fn_showToast('exception');
        }
        else{
            var headersObj = {
              headers: { 
                'Authorization': 'bearer '+token
              }
            };

            let dataObj = {};
            dataObj.Action = "Open";
            dataObj.ObjectType = ObjectType;
            dataObj.ObjectTitle = ObjectTitle;
            dataObj.ObjectID = ObjectID;
            dataObj.TripSiteID = this.tripSiteId;

            axios.post(this.baseUrl + "/api/mobileapp/metrics", dataObj, headersObj, {timeout:30000})
            .then(response => {
                this.loading = false;
                console.log(response);
                
            }).catch(e => {
                this.loading = false;
                console.log(e);
                // this.$root.fn_showToast('exception');
            })
        }
      },

      async fn_postClickLinkMetrics(Object){

        console.log('Click Metrics');
        //console.log(this.tripSiteId);
        
        if(!navigator.onLine){
          this.$root.fn_showToast("nointernet");
          return;
        }

        var token = await new Promise((resolve) => {
          this.$root.fn_getToken((token) => {
              resolve(token);
          });
        });

        if(token == 'error'){
          this.$root.fn_showToast('exception');
        }
        else{
            var headersObj = {
              headers: { 
                'Authorization': 'bearer '+token
              }
            };

            let dataObj = {};
            dataObj.Action = "Click";
            dataObj.ObjectType = Object.ObjectType;
            dataObj.ObjectTitle = Object.ObjectTitle;
            dataObj.ObjectID = null;
            dataObj.LinkPath = Object.LinkPath;
            dataObj.ActionType = "Link";
            dataObj.TripSiteID = this.tripSiteId;

            axios.post(this.baseUrl + "/api/mobileapp/metrics", dataObj, headersObj, {timeout:30000})
            .then(response => {
                this.loading = false;
                console.log(response);
                
            }).catch(e => {
                this.loading = false;
                console.log(e);
                // this.$root.fn_showToast('exception');
            })
        }
      },

      fn_postAccordianClickLinkMetrics: function(Object){

        console.log('Accordian Metrics');
       
        let self = this;
        
        if(!navigator.onLine){
          this.$root.fn_showToast("nointernet");
          return;
        }

        this.$root.fn_getToken(token=>{
            if(token == 'error'){
                this.$root.fn_showToast('exception');
            }
            else{

              var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token
                    }
                };

                let dataObj = {};
                dataObj.Action = "Click";
                dataObj.ObjectType = Object.ObjectType;
                dataObj.ObjectTitle = Object.ObjectTitle;
                dataObj.ObjectID = null;
                dataObj.LinkPath = Object.LinkPath;
                dataObj.ActionType = Object.ActionType;
                dataObj.TripSiteID = this.tripSiteId;

                axios.post(this.baseUrl + "/api/mobileapp/metrics", dataObj, headersObj, {timeout:30000})
              .then(response => {
                //self.loading = false;
                this.loading = false;
                console.log(response);
                  
              }).catch(e => {
                self.loading = false;
                console.log(e);
                  // this.$root.fn_showToast('exception');
              })
            }
        })
      },



      // async fn_postMetricsV2() {
      //   console.log('Metrics');
    
      //   if (!navigator.onLine) {
      //       this.$root.fn_showToast("nointernet");
      //       return;
      //   }
    
      //   try {
      //       var token = await new Promise((resolve) => {
      //           this.$root.fn_getToken((token) => {
      //               resolve(token);
      //           });
      //       });
    
      //       if (token === 'error') {
      //           this.$root.fn_showToast('exception');
      //       } 
      //       else {
      //           const headersObj = {
      //               headers: {
      //                   'Authorization': 'bearer ' + token
      //               }
      //           };
    

      //           var response = await axios.get(this.baseUrl + "/api/mobileapp/attendee/personalinformation", headersObj, { timeout: 30000 });
      //           this.loading = false;
      //           console.log(response);

      //           if (response.data === false) {
      //               this.$router.push('/sendotp');
      //           }

      //           // await axios.get(this.baseUrl + "/api/mobileapp/attendee/personalinformation", headersObj, { timeout: 30000 })
      //           // .then(response => {
      //           //   this.loading = false;
      //           //   console.log(response);
      //           //   if (response.data === false) {
      //           //       this.$router.push('/sendotp');
      //           //   }
      //           // }).catch(e => {
      //           //     this.loading = false;
      //           //     console.log(e);
      //           // });
                
      //       }
      //   } catch (e) {
      //       this.loading = false;
      //       console.error(e);
      //       // this.$root.fn_showToast('exception');
      //   }
      // }
    
      // fn_saveAttendeeInfo: function(attendeeInfo){
      //   let dataObj = {};
      //   dataObj.attendeeInfo = attendeeInfo;
      //   this.postData('AttendeeInfo', dataObj,{
      //       onSuccess: function(response){
      //           console.log(response);
      //       },
      //       onError: function(error){
      //           console.log(error);
      //       }
      //   })
      // },

      // fn_getAttendeeInfo: function(){
      //   // let self = this;
      //   this.getData('AttendeeInfo', null,{
      //       onSuccess: function(response){
      //           console.log(response);
      //       },
      //       onError: function(error){
      //           console.log(error);
      //       }
      //   })
      // },

      fn_pageLoadComplete: function(){
        var self = this;
        this.getData('onPageLoadCompleted', null, 
          {
              onSuccess: function(response){
                  console.log(response);
                  this.Envirenment = localStorage.getItem('Envirenment');
                  console.log(this.Envirenment);
                  var chatUrl = null;
                  if(this.Envirenment == 3)
                    chatUrl = "https://clubtrip-chatapp.web.app/";
                  else
                    chatUrl = "https://pcgctripchat-uat.web.app/";
                  let dataObj = {"chatUrl": chatUrl + "?tid="+self.tripSiteId+"&notification=1"};
                  self.postData("PostChatUrlToLocal", dataObj,
                  {
                      onSuccess: function(response){
                          console.log(response);
                      },
                      onError: function(error){
                          console.log(error);
                      }
                  });
              },
              onError: function(error){
                  console.log(error);
              }
          })
        },

        // fn_openNotification: function(notificationId){
        //   console.log('open notification');
        //   this.$router.push('/notifications/'+notificationId);
        // },

        fn_openNotification: function(alertObj){
          console.log('open notification');
          console.log(JSON.stringify(alertObj));
          // let notificationObj = this.platform === 'ios' ? alertObj : JSON.parse(alertObj);
          let notificationObj = null
          // if(this.Envirenment != 3){
          //   if(alertObj?.message){
          //     notificationObj = JSON.parse(alertObj.message);
          //   }
          //   else{
          //     notificationObj = this.platform === 'ios' ? alertObj : JSON.parse(alertObj);
          //   }
          // }
          // else{
          //   notificationObj = this.platform === 'ios' ? alertObj : JSON.parse(alertObj);
          // }

          if(alertObj?.message){
            notificationObj = JSON.parse(alertObj.message);
          }
          else{
            notificationObj = this.platform === 'ios' ? alertObj : JSON.parse(alertObj);
          }
          

          if(notificationObj.tripsiteid){
            if(Number(notificationObj.tripsiteid) != Number(this.tripSiteId)){
              console.log('switch trip');
              this.notificationObj = notificationObj;
              this.alertDialog = true;
            }
            else{
              if(notificationObj.pagename){
                localStorage.setItem('n_id', notificationObj.notificationid)
                localStorage.setItem('p_name', notificationObj.pagename)
                let trip = notificationObj.tripsiteid == 20 ? '/platinumclub' : notificationObj.tripsiteid == 17 ? '/gcamericas' : notificationObj.tripsiteid == 18 ? '/gcasia' :  '/gcemea';
                this.$router.replace({path:trip});
              }
              else{
                this.$router.push('/notifications/'+notificationObj.notificationid);
              }
            }
          }

          // if(this.Envirenment != 3){
          //   if(notificationObj && notificationObj.category && notificationObj.category == "message"){
          //     this.fn_openChatView('notification');
          //   }
          // }

          if(notificationObj && notificationObj.category && notificationObj.category == "message"){
            this.fn_openChatView('notification');
          }
          
        },

        fn_openChatView: function(type, attendeeId){
          if(type == 'notification' && this.isChatViewOpen){
            this.isChatViewOpen = false;
            this.fn_dissmissChatView();
          }
          else if(this.isChatViewOpen){
            console.log('Chat View Called');
            this.isChatViewOpen = false;
            this.fn_gotoChatView(attendeeId);
            return;
          }

          if(this.platform === 'ios'){
            this.fn_enterChatView(type, attendeeId);
          }
          else{
            let self = this;
            this.getData('CheckNotificationPermission', 'NotificationPermission', {
              onSuccess: function(response){
                  console.log(response);
                  self.fn_enterChatView(type, attendeeId);
              },
              onError: function(error){
                  console.log(error);
              }
            });
          }
        },

        fn_enterChatView: function(type, attendeeId){
          let self = this;
          console.log('chatview');
          let isWebViewStarted = false;
          setTimeout(() => {
            if(!isWebViewStarted){
              // this.fn_showToast("Update app to access chat feature");
              this.appsnackbar = true;
            }
          }, 1000);
          let postObj = {};
          let baseChatUrl = "";
          console.log(this.Envirenment);
          if(this.Envirenment == 3){
            baseChatUrl = "https://clubtrip-chatapp.web.app/";
          }
          else{
            baseChatUrl = "https://pcgctripchat-uat.web.app/";
          }

          if(type == 'notification')
            postObj.url = baseChatUrl + "?tid="+this.tripSiteId+"&notification=1";
          else if(type == 'instantchat')
            postObj.url = baseChatUrl + "?tid="+this.tripSiteId+"&aid="+attendeeId;
          else
            postObj.url = baseChatUrl + "?tid="+this.tripSiteId;

          // if(type == 'notification')
          //   postObj.url = "https://pcgctripchat-uat.web.app/?notification=1";
          // else if(type == 'instantchat')
          //   postObj.url = "https://pcgctripchat-uat.web.app/?aid="+ attendeeId;
          // else
          //   postObj.url = "https://pcgctripchat-uat.web.app/";
          // postObj.url = "http://pcgctripchat-uat.web.app/"
          this.postData('ShowChatView', postObj,
          {
              onSuccess: function(response){
                console.log(response);
                isWebViewStarted = true;
              },
              onError: function(error){
                console.log(error);
                isWebViewStarted = true;
                self.showToast('Something went wrong. Try again!.');
              }
          });
        },

        fn_routeToTrips: function(){
          this.alertDialog = false;
          let notificationid = this.notificationObj.notificationid;
          let pagename = this.notificationObj.pagename;
          let tripsiteid = this.notificationObj.tripsiteid;
          this.notificationObj = null;
          this.$router.push({path:'/trips', query:{goback:1, nid:notificationid, pname:pagename, tsid:tripsiteid }});
        },

        fn_notificationReceived: function(notificationObj){
          notificationObj.tripsiteid == this.tripSiteId ? this.tripOnLoadData.NotificationCount++ : '';
          console.log('notification receive');
        },

        fn_openExternalPage: function(pageUrl){
          this.getData('OpenBrowser', pageUrl, {
            onSuccess: function(response){
                console.log(response);
            },
            onError: function(error){
                console.log(error);
            }
          });
        },

        fn_call: function(phoneNumber){
          this.getData('CallToNumber', phoneNumber, {
            onSuccess: function(response){
                console.log(response);
            },
            onError: function(error){
                console.log(error);
            }
          });
        },

        fn_checkPDF: function(filePath){
          let self = this;
          if(filePath.indexOf('ShuttleMap_20') != -1){
            self.PdfHeader = 'Shuttle Map';
          }
          else{
            self.PdfHeader = 'Hotel / Resort Map';
          }
          console.log(self.PdfHeader);
          
          let fileName = filePath.substring((filePath.lastIndexOf('/'))+1);
          console.log(filePath);
          this.getData('GetPDF', fileName, {
            onSuccess: function(response){
                console.log(response);
                if(JSON.parse(response)){
                  self.fn_openPDF(fileName);
                }
                else{
                  self.fn_downloadPDF(filePath);
                }
            },
            onError: function(error){
                console.log(error);
            }
          });
        },

        fn_downloadPDF: function(filePath){
            let self = this;
            let dataObj = {};
            dataObj.FileName = filePath.substring((filePath.lastIndexOf('/'))+1);
            dataObj.FileUrl = filePath;
            dataObj.DisplayName = dataObj.FileName;
            // this.downloading = true;
            console.log('downloading');
            this.loader = true;
            this.getServerData('DownloadFile', dataObj, {
                onSuccess: function(response){
                    console.log(response);
                    console.log('downloaded');
                    self.loader = false;
                    self.fn_openPDF(dataObj.FileName);
                }, 
                onError: function(error){
                    console.log(error);
                    self.loader = false;
                    self.$root.fn_showToast('exception');
                }, 
            });
        },

        // fn_openPDF: function(fileName){
        //   this.getData('OpenPDF', fileName, {
        //     onSuccess: function(response){
        //         console.log(response);
        //     },
        //     onError: function(error){
        //         console.log(error);
        //     }
        //   });
        // },

        fn_openPDF: function(fileName){
          let filterObject = {};
          filterObject.PdfHeader = this.PdfHeader;
          console.log(filterObject.PdfHeader);
          this.getFilterData('OpenPDF', fileName, filterObject, {
              onSuccess: function(response){
                console.log(response);
              }, 
              onError: function(error){
                console.log(error);
              }
          });
        },

        fn_saveLocalData: function(dataKey, dataValue, callback){
            let dataObj = {};
            dataObj.TripSiteID = this.tripSiteId;
            dataObj.dataKey = dataKey;
            dataObj.dataValue = JSON.stringify(dataValue);
            // let self = this;
            this.postData("LocalData", dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        callback ? callback() : '';
                        // self.fn_getLocalData(dataKey);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_getLocalData: function(dataKey, callback){
            let filterObject = {};
            filterObject.TripSiteID = Number(this.tripSiteId);
            filterObject.dataKey = dataKey;
            console.log(JSON.stringify(filterObject));
            this.getFilterData('LocalData', null, filterObject,
                {
                    onSuccess: function(response){
                        console.log(response);
                        let data = response && response.dataValue ? response.dataValue : null;
                        callback ? callback(JSON.parse(data)) : '';
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        // fn_refreshWebApp: function(){
        //   localStorage.setItem('reload_trip_id', this.tripSiteId);
        //   location.assign(location.origin);
        // },

        fn_checkStoragePermission: function(callback){
          this.getData('CheckStoragePermission', 'StoragePermission', {
            onSuccess: function(response){
              console.log(response);
              callback ? callback(response) : '';
            },
            onError: function(error){
              console.log(error);
            }
          })
        },

        fn_checkCameraPermission: function(callback){
          var self = this;
          let itemId = this.$root.platform === 'ios' ? null : 'CameraPermission';
          this.getData("CheckCameraPermission", itemId, 
              {
                  onSuccess: function(response){
                      console.log(response);
                      callback();
                  }, 
                  onError: function(error){
                      console.log(error);
                      self.showToast("Please allow camera permission");
                  }
              }
          );
        },

        fn_goBack: function(){
          this.$route.name === 'homepage' ? this.exitApp() : this.$router.go(-1);
        },

        fn_showAlert: function(type, callFromInside){
          console.log('show dialog alert');
          if(callFromInside && localStorage.getItem('donotaskfornotification')){
              localStorage.removeItem('donotaskfornotification');
          }
          let alertObj = {};
          alertObj.type = type;
          this.$eventHub.$emit('ShowAlert', alertObj);
        },

        fn_openPermissionDialog: function(permissionType){
          console.log('show dialog alert');
          this.permissionType = permissionType;
          this.$eventHub.$emit('PermissionDialog');
        },

        fn_gotoPermissionSettings: function(){
          this.getData('ShowPermissionSettings', this.permissionType, {
              onSuccess: function(response){
                console.log(response);
              },
              onError: function(error){
                console.log(error);
              }
          });
        },

        fn_getUnreadMessageCount: function(){
          console.log('Get Read Message Count Initiated');
          if(localStorage.getItem("pn_registered_"+this.tripSiteId)){
            console.log('Get Read Message Count Called');
            CometChat.getUnreadMessageCount().then(array => {
              console.log("Message count fetched", array);
              this.unreadMessageCount = 0;
              for(let group in array){
                  for(let key in array[group]){
                      this.unreadMessageCount += array[group][key];
                  }
              }
            }, error => {
              console.log("Error in getting message count", error);
            });
          }
        },

        fn_focusToMainApp: function(){
          console.log('main app focused');
          this.fn_getUnreadMessageCount();
        },

        getPhotoURLOld: function (photourl) {
          return this.baseUrl + photourl
        },

        // async getPhotoURL(photourl){
        //   const photoURL = await this.getPhotoURLApiCall(photourl);
        //   return photoURL; // Should print the base64 enc
        // },

        getPhotoURL(photourl){
         
          if (!navigator.onLine) {
            this.fn_showToast("nointernet");
            return null;
          }
      
          // Get token
          let token;
          try {
              token = new Promise((resolve) => {
                  this.fn_getToken((token) => {
                      resolve(token);
                  });
              });
          } catch (error) {
              this.fn_showToast('exception');
              return null;
          }
      
          // Handle token errors
          if (token === 'error') {
              this.fn_showToast('exception');
              return null;
          }
      
          // Set headers with the token
          const headersObj = {
              headers: { 
                  'Authorization': 'Bearer ' + token
              },
              responseType: 'arraybuffer'
          };
      
          // Make the API request
          try {
              const getUrl = this.baseUrl + photourl;
              const response = axios.get(getUrl, headersObj, { timeout: 30000 });
              // const base64 = Buffer.from(response.data, 'binary').toString('base64');
              console.log(response.data + 'Response');
              return 'data:image/png;base64, ' + response.data;
          } catch (error) {
              this.fn_showToast('exception');
              return null;
          }
        },

        fn_showUpdate: function(url, newUrl){
          let currentVer = url.split('?')[1].split('=')[1];
          let newVer = newUrl.split('?')[1].split('=')[1];
          if(currentVer && newVer && currentVer != newVer){
            this.$eventHub.$emit('updatefound');
          }
        },

        fn_gotoChatView: function(aid){
          console.log('Chat View Called');
          let attendeeId = aid ? aid.toString() : null;
          this.getData('OpenChatView', attendeeId,
            {
              onSuccess: function(response){
                  console.log(response);
              },
              onError: function(error){
                  console.log(error);
              }
          });
        },

        fn_dissmissChatView: function(){
          this.getData('CloseChatView', null,
            {
              onSuccess: function(response){
                  console.log(response);
              },
              onError: function(error){
                  console.log(error);
              }
          });
        },

        fn_mainView: function(){
          console.log('main view open');
          this.isChatViewOpen = true;
          if(this.$route.name == 'homepage'){
            // if(localStorage.getItem('lastactivetab') != 2){
            //   this.$eventHub.$emit('GetAttendees');
            // }
            this.$eventHub.$emit('GetAttendees');
          }
          else{
            localStorage.setItem('lastactivetab', 2);
            this.$router.push({path:'/platinumclub'});
          }
        },

        
    },
    mounted() {

      window.js_globals.fn_openPermissionDialog = this.fn_openPermissionDialog;
      window.js_globals.fn_focusToMainApp = this.fn_focusToMainApp;
      //this.fn_getEnvirenment();
      window.js_globals.fn_openNotification = this.fn_openNotification;
      window.js_globals.fn_notificationReceived = this.fn_notificationReceived;
      window.js_globals.fn_onBackPress = this.fn_goBack;
      window.js_globals.fn_showAlert = this.fn_showAlert;
      window.js_globals.fn_showUpdate = this.fn_showUpdate;
      window.js_globals.fn_mainView = this.fn_mainView;
      // window.js_globals.fn_setFileData = fn_setFileData;

      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosMessageInterface) {
        this.platform = "ios"
      }
      /*global AndroidRoutingTest*/
      else if (typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null) {
        this.platform = "android"
      }
      else {
        this.platform = "web"
      }

      let self = this;
      addEventListener('click', function (event) {
        event.preventDefault();                     // Don't navigate!
        const anchor = event.target.closest("a");   // Find closest Anchor (or self)
        if (!anchor) return;
        console.log( anchor.getAttribute('type'));                        // Not found. Exit here.
        console.log( anchor.getAttribute('href'));  // Log to test
        let type = anchor.getAttribute('type');
        let href = anchor.getAttribute('href');

        let pageUrl = null;
        // checking if link is of map
        // if(href.substring(href.lastIndexOf('/')+1) == 'resort_map_' +self.tripSiteId+ '.pdf'){
        if(href && href.substring(href.lastIndexOf('.')+1) == 'pdf'){
          pageUrl = location.origin + href;
        }
        else{
          pageUrl = href
        }

        // type == 'maplink' ? href = location.origin + href : '';
        if(type == 'maplink' || (pageUrl && pageUrl.indexOf('resort_map_') != -1)){
          self.fn_checkPDF(pageUrl);
        }
        else if(type == 'tel' && href){
          let phoneNumber = href.substring(href.lastIndexOf(':')+1)
          self.fn_call(phoneNumber);
        }
        else{
          self.fn_openExternalPage(pageUrl);
        }

        if(self.$route.name != 'keylocationsemea' && self.$route.name != 'keylocationsamericas' && self.$route.name != 'keylocationsasia' && self.$route.name != 'keylocationsplatinumclub'){
          if (event.target.tagName === 'A') {
            let tempObj = {};
            // Perform actions to capture link click metrics
            tempObj.ObjectType = self.$route.name;
            tempObj.LinkPath = event.target.href;
            tempObj.ObjectTitle = event.target.textContent;
            tempObj.ActionType = "Link";
            self.fn_postClickLinkMetrics(tempObj);
          }
        }
        
        

       
      });

      // this.fn_getLocalData('AttendeeInfo', response=>{
      //   this.tripOnLoadData.AttendeeInfo = JSON.parse(response);
      // });
      
    },
    render: h => h(App)
}).$mount('#app')

// assigning transition class
let navigationHistory = [];
router.afterEach((to, from) => {
  let itemIndex = navigationHistory.indexOf(to.name);
  if(itemIndex == -1){
    to.meta.transitionName = 'slideLeft';
    navigationHistory.push(from.name);
  }
  else{
    to.meta.transitionName = 'slideRight';
    navigationHistory.splice(itemIndex, 1);
  }

  // const toDepth = to.path.split('/').length
  // const fromDepth = from.path.split('/').length
  // to.meta.transitionName = toDepth < fromDepth ? 'slideRight' : 'slideLeft'
})

// initFirebaseAuth();
// signIn();
// Vue.prototype.fn_signinToFirebase();